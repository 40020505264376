<template>
  <div>
    <!-- 活跃度弹窗 -->
    <div class="mask1" v-show="flag">
      <div>
        <img class="imgs" src="../../src/assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">
            <span>亲！</span> 你还在学习吗<br /><span class="span"
              >如果在的话，请点我哦！</span
            >
          </div>
          <div class="xian"></div>
          <div class="dian" @click="normal">请点我</div>
        </div>
        <img
          class="img"
          src="../../src/assets/close.png"
          alt=""
          @click="flag = false"
        />
      </div>
    </div>
    <!-- //时间停止计时警告 -->
    <div class="stop_mask" v-if="flag2">
      <div>
        <img class="imgs" src="../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">
            <span>亲！</span> 您当前页面学习时长已停止记录<br /><span
              class="span"
            >
            </span>
          </div>
          <div class="xian"></div>
          <div class="dian" @click="goon">点我继续计时</div>
        </div>
        <img class="img" @click="goon" src="../assets/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      flag: false,
      flag2: false,

      dynamic_time: null,
      start_time: "",
      respond_time: "",
      flag1: true,
      num: 1,
      leveTime: null, //离开页面时间
      backTime: null, //返回页面时间
      begin_time: null,
      reset: true,
      popup_time: null,
      human_time: null,
      Socket_sta: null,
      Socket_end: null,
      screen: true, //切屏停止计时
      flags: false,
      flagss: false,
      flag: false,
      tz_time: 0,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
      url: (state) => state.url,
      school: (state) => state.school,
      studyType: (state) => state.studyType,
      details: (state) => state.details,
      human: (state) => state.human,
    }),
  },
  methods: {
    ...mapActions(["getactivityDetection", "getactivityDetection"]),
    goon() {
      this.flag2 = false;
      this.tz_time = 0;
      this.$emit("gx", this.tz_time);
    },
    normal() {
      clearTimeout(this.dynamic_time);
      clearTimeout(this.popup_time);
      this.tz_time = 0;
      this.$emit("gx", this.tz_time);
      this.flag1 = false;
      this.socketLoad = true;
      this.respond_time = new Date(+new Date() + 8 * 3600 * 1000)
        .toJSON()
        .substr(0, 19)
        .replace("T", " ");
      this.getactivityDetection({
        username: this.userInfo.username,
        id: this.userInfo.id,
        status: 1,
        source: 2,
        start_time: this.start_time,
        respond_time: this.respond_time,
      });
      this.flag = false;
    },
    dynamic() {
      let that = this;
      // let num = 5 * 60 * 1000;
      let num = 30 * 60 * 1000;
      //随机弹窗
      let str = Math.round(Math.random() * num);
      if (!this.flag1 || this.num > 2) return; // 停止回调
      that.popup_time = setTimeout(() => {
        that.flag = true;
        that.start_time = new Date(+new Date() + 8 * 3600 * 1000)
          .toJSON()
          .substr(0, 19)
          .replace("T", " ");
        if (!this.flag1 || this.num > 2) return; // 停止回调
        that.dynamic_time = setTimeout(() => {
          that.getactivityDetection({
            username: that.userInfo.username,
            student_id: that.userInfo.id,
            status: 2,
            source: 2,
            start_time: that.start_time,
          });
          if (that.num == 2) {
            that.flag2 = true;
            that.socketLoad = false;
            that.tz_time = new Date().getTime();
            that.$emit("gx", that.tz_time);
          }
          that.num = that.num + 1;
          that.flag = false;
          if (this.num > 2) return; // 停止回调
          that.dynamic();
        }, 30000);
      }, str);
    },
  },
  beforeDestroy() {
    let that = this;
    //必须内存地址相同才能移出方法
    this.flag1 = false;
    clearInterval(this.time);
    clearTimeout(this.human_time);
    clearTimeout(this.dynamic_time);
    clearTimeout(this.begin_time);
  },
  mounted() {
    this.begin_time = setTimeout(() => {
      this.dynamic();
    }, 30 * 60 * 1000);
  },
};
</script>

<style lang='scss' scoped>
.mask1 {
  position: fixed;
  z-index: 99;

  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .imgs {
    display: block;
    width: 200px;
    margin: 0 auto;
    vertical-align: middle;
  }
  .img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 15px auto;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 227px;
    height: 143px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      text-align: center;
      padding: 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      line-height: 26px;
      span {
        font-size: 18px;
      }
      .span {
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
    }
    .xian {
      width: 100%;
      height: 2px;
      background-color: #eaeaea;
    }
    .dian {
      cursor: pointer;
      margin-top: 15px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
    }
  }
}
</style>