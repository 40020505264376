<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["type"],
  data() {
    return {
      strtime: null,
      endtime: null,
      time: null,
    };
  },
  computed: {
    ...mapState({
      details: (state) => state.details,
      userInfo: (state) => state.userInfo,
      url: (state) => state.url,
      school: (state) => state.school,
      studyType: (state) => state.studyType,
    }),
  },
  methods: {
    ...mapActions(["getscreenCuttingDuration"]),
  },
  mounted() {
    let num = 1;
    this.time = setInterval(() => {
      ++num;
    }, 1000);
    this.strtime = new Date();
  },
  beforeDestroy() {
    clearInterval(this.time);
    let that = this;
    this.endtime = new Date();
    let date3 = this.endtime.getTime() - this.strtime.getTime();
    if ((date3 / 1000).toFixed(0) < 300) {
      this.getscreenCuttingDuration({
        username: that.userInfo.username,
        user_id: that.userInfo.id,
        class_id: that.details.id,
        video_time: (date3 / 1000).toFixed(0),
        source: that.type,
      });
    } else if ((date3 / 1000).toFixed(0) > 300) {
      this.getscreenCuttingDuration({
        username: that.userInfo.username,
        user_id: that.userInfo.id,
        class_id: that.details.id,
        video_time: (date3 / 1000).toFixed(0) % 300,
        source: that.type,
      });
    }
  },
};
</script>

<style lang='scss' scoped>
</style>