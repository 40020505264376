<template>
  <div class="header">
    <div class="wh1">
      <div class="box">
        <img @click="gohome" class="logo" src="../assets/logo@3x.png" alt="" />
        <div>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <img :src="username_img" alt="" class="img" />
              学员 {{ tel }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      tel: "",
      username_img: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  mounted() {
    var tel = String(this.userInfo.phone);
    var dh = tel.substr(0, 3) + "******" + tel.substr(8);
    this.tel = dh;
    if (this.userInfo.headpic) {
      this.username_img = this.userInfo.headpic;
    } else {
      if (this.userInfo.sex == null) {
        this.username_img = require("../assets/moren_touxiang.png");
      } else {
        if (this.userInfo.sex == 1) {
          this.username_img = require("../assets/nan_touxiang.png");
        } else {
          this.username_img = require("../assets/nv_touxiang.png");
        }
      }
    }
  },
  methods: {
    ...mapActions(["getLoginOut"]),
    handleCommand(command) {
      let that = this;
      if (command == "a") {
        that.$router.push("/login");
        setTimeout(() => {
          that
            .getLoginOut({
              username: that.userInfo.username,
            })
            .then((res) => {
              if (res.code == 200) {
                that.$store.commit("SET_RESETUSER");
              }
            });
        }, 1000);
      }
    },
    gohome() {
      this.$router.push("/");
      // window.open("www.zpton.com");
      // window.open("https://www.zpton.com");
    },
  },
};
</script>

<style lang="scss" scoped>
.wh1 {
  width: 1300px;
  margin: 0 auto;
}
.header {
  height: 90px;
  background: #ffffff;
  .box {
    line-height: 90px;
    display: flex;
    justify-content: space-between;
    .logo {
      cursor: pointer;
      margin-top: 12px;
      width: 182px;
      height: 61px;
    }
    img {
      vertical-align: middle;
    }
  }
}
.img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}
</style>
