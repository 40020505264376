<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import base from "../libs/base";

export default {
  props: ["type", "record"], //type1. 视频 2. 音频 3. h5 4. 考试//record  1 记录 2 不记录
  computed: {
    ...mapState({
      details: (state) => state.details,
      userInfo: (state) => state.userInfo,
      url: (state) => state.url,
      school: (state) => state.school,
      studyType: (state) => state.studyType,
      human: (state) => state.human,
      human_src: (state) => state.human_src,
    }),
  },
  data() {
    return {
      leveTime: null, //离开页面时间
      backTime: null, //返回页面时间
      begin_time: null,
      reset: true,
      popup_time: null,
      human_time: null,
      Socket_sta: null,
      Socket_end: null,
      screen: true, //切屏停止计时
    };
  },
  methods: {
    ...mapActions(["getscreenCuttingDuration"]),
    getdocument() {
      let that = this;
      if (document.hidden) {
        this.screen = false;
        // 页面被挂起
        that.leveTime = new Date();
      } else {
        this.screen = true;
        // 页面呼出
        that.backTime = new Date();
        var date3 = that.backTime.getTime() - that.leveTime.getTime();
        that.getscreenCuttingDuration({
          username: that.userInfo.username,
          user_id: that.userInfo.id,
          class_id: that.details.id,
          video_time: (date3 / 1000).toFixed(0),
          source: that.type,
        });
        // }
      }
    },
    socketInit() {
      this.socket = new WebSocket(base.socketUrl);
      var that = this;

      this.Socket_sta = new Date();
      this.socket.onopen = function () {
        //补回连接中的时间段
        that.Socket_end = new Date();
        var date3 = that.Socket_end.getTime() - that.Socket_sta.getTime();

        that.getscreenCuttingDuration({
          username: that.userInfo.username,
          user_id: that.userInfo.id,
          class_id: that.details.id,
          video_time: (date3 / 1000).toFixed(0),
          source: that.type,
        });
        // }
        that.socketLoad = true;
      };
    },
    sendSocketMsg() {
      this.time = setInterval(() => {
        this.lock = true;
        if (this.socketLoad) {
          this.socket.send(
            JSON.stringify({
              type: 1,
              classId: this.details.id,
              userId: this.userInfo.id,
              source: this.type,
            })
          );
        }
      }, 5 * 60 * 1000);
    },
  },
  mounted() {
    let that = this;
    if (this.record == 1) {
      setTimeout(() => {
        that.socketInit();
        that.sendSocketMsg();
      }, 500);

      document.addEventListener("visibilitychange", that.getdocument, false);
    }
  },
  beforeDestroy() {
    let that = this;
    //必须内存地址相同才能移出方法
    if (this.record == 1) {
      document.removeEventListener("visibilitychange", that.getdocument, false);
    }
    this.flag1 = false;
    clearInterval(this.time);
    clearTimeout(this.human_time);
    clearTimeout(this.dynamic_time);
    clearTimeout(this.begin_time);
    this.socket.close();
  },
};
</script>

<style>
</style>