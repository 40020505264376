import axios from "axios";
import store from "../store";
import router from "../router";

// 创建axios实例
const Http = axios.create({
  //baseURL: ajaxUrl, // api 的 base_url
  timeout: 15000, // 请求超时时间//加大请求时间数据过大
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});
// request拦截器
Http.interceptors.request.use(
  (config) => {
    if (store.state.userInfo) {
      //config.headers['X-Token'] = store.state.userInfo.token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
Http.interceptors.response.use(
  (response) => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    const codeReg = /^20\d+/;
    //token失效
    if (res.code == 2001 || res.code == 2002) {
      router.replace({
        path: "/login",
      });
      return false;
    }
    if (!codeReg.test(response.status)) {
      //    Message.info({
      //      content: res.info
      //    })
      return Promise.reject("error");
    } else {
      return response.data;
    }
  },
  (error) => {
    // router.replace({
    //   path: "/login",
    // });
    console.log("err" + error); // for debug
    //12 探测请求的 屏蔽
    if (error.code != 12) {
    }

    return Promise.reject(error);
  }
);

export default Http;
