<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    type: {
      required: true,
    },
    iszp: {
      default: 0,
    },
    //停止计时结束时间，中间没有停止时间为0
    tz_time: {
      default: 0,
    },
  },
  computed: {
    ...mapState({
      details: (state) => state.details,
      userInfo: (state) => state.userInfo,
      url: (state) => state.url,
      school: (state) => state.school,
      studyType: (state) => state.studyType,
      classid: (state) => state.classid,
    }),
  },
  data() {
    return {
      str: null,
      end: null,
      flag: true,
    };
  },
  methods: {
    ...mapActions(["getscreenCuttingDuration"]),
    gettime() {
      let that = this;
      this.end = null;
      this.end = new Date();
      let date3 = null;
      // date3 = this.end.getTime() - this.str.getTime();
      if (this.tz_time > 0) {
        date3 = this.tz_time - this.str.getTime();
      } else {
        date3 = this.end.getTime() - this.str.getTime();
      }
      // document.removeEventListener("beforeunload", that.gettime, false);
      if (date3 < 1000) return;
      if (this.flag) {
        this.getscreenCuttingDuration({
          username: that.userInfo.username,
          user_id: that.userInfo.id,
          class_id: that.details.id,
          video_time: (date3 / 1000).toFixed(0),
          source: that.type,
          video_id: "", //视频id
          is_finished: "1", //是否学完 1-完成 2-未完成
          last_viewing_time: "",
        });
      }
    },
    createBeforeunloadHandler() {
      let that = this;
      window.addEventListener("beforeunload", that.gettime, false);
    },
    destroyedBeforeunloadHandler() {
      let that = this;
      document.removeEventListener("beforeunload", that.gettime, false);
    },
  },

  mounted() {
    let that = this;
    this.str = null;
    this.str = new Date();
    this.createBeforeunloadHandler();
  },
  beforeDestroy() {
    let that = this;
    this.end = null;
    this.end = new Date();
    let date3 = null;
    // date3 = this.end.getTime() - this.str.getTime();
    if (this.tz_time > 0) {
      date3 = this.tz_time - this.str.getTime();
    } else {
      date3 = this.end.getTime() - this.str.getTime();
    }
    if (date3 < 1000) return;
    this.destroyedBeforeunloadHandler();
    this.flag = false;
    this.getscreenCuttingDuration({
      username: that.userInfo.username,
      user_id: that.userInfo.id,
      class_id: that.details.id,
      video_time: (date3 / 1000).toFixed(0),
      source: that.type,
      video_id: "", //视频id
      is_finished: "1", //是否学完 1-完成 2-未完成
      last_viewing_time: "",
    });

    //必须内存地址相同才能移出方法
  },
};
</script>

<style lang='scss' scoped>
</style>