import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/css.css";
Vue.config.productionTip = false;
import header from "./components/header.vue";
import header1 from "./components/header1.vue";
import footers from "./components/footer.vue";
import advertising from "./components/advertising.vue";
import recordtime from "./components/recordtime.vue";
import popups from "./components/popup.vue"; showtime
import showtime from "./components/showtime.vue";
import writetime from "./components/writetime.vue";

import "./font/iconfont.css";
Vue.component("headers", header);
Vue.component("headers1", header1);
Vue.component("footers", footers);
Vue.component("advertising", advertising);
Vue.component("recordtime", recordtime);
Vue.component("showtime", showtime);
Vue.component("popups", popups);
Vue.component("writetime", writetime);
//引入百度地图
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "hA23kuotRHq2xoQDhakYi6NrAVSZUge7",
});
//引入音频组件
import AudioPlayer from "@liripeng/vue-audio-player";
import "@liripeng/vue-audio-player/lib/vue-audio-player.css";
Vue.use(AudioPlayer);
//引入视频组件
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
