import api from "@/libs/reset.js";
import md5 from "js-md5";
const udao = "udao20171024"; //md5公共的加密混入字符串
export default {
  //用户名登录
  async getLogin({ commit }, param) {
    const data = {
      username: param.username,
      pwd: md5(param.pwd),
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Login/Login", data);
    if (res.code == 200) {
      let num = Math.round(Math.random() * 42);
      commit("SET_USERINFO", res.data);
      commit("STE_HUMAN", num);
    }
    return res;
  },
  //手机验证码登录
  async getPhoneLogin({ commit }, param) {
    const data = {
      ...param,
      code: md5(udao)
    };
    const res = await api.post("/index/Login/PhoneLogin", data);
    if (res.code == 200) {
      let num = Math.round(Math.random() * 42);
      commit("SET_USERINFO", res.data);
      commit("STE_HUMAN", num);
    }
    return res;
  },
  //第三方登录绑定手机号
  async getBound({ commit }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Login/Bound", data);
    if (res.code == 200) {
      commit("SET_USERINFO", res.data);
    }
    return res;
  },
  //pc微信登录
  async getweChatLogin({ commit }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/Login/weChatLogin", data);
    if (res.code == 200) {
      commit("SET_USERINFO", res.data);
    }
    return res;
  },
  //退出登录
  async getLoginOut({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    commit("SET_SCHOOL", "");
    const res = await api.post("/index/Login/LoginOut", data);
    return res;
  },
  async getClassHour({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/ClassHour", data);
    return res;
  },
  //上传图片
  async getPictureUpload({ commit }, param) {
    const res = await api.post("/index/Mine/PictureUpload", param);
    return res;
  },
  //提问列表
  async getMineQuestionList({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/QuestionList", data);
    return res;
  },
  //提问
  async getMinePutQuestion({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/PutQuestion", data);
    return res;
  },
  //提问详情
  async getMineQuestionDetail({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/QuestionDetail", data);
    return res;
  },
  //修改头像
  async getEditInfo({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/EditInfo", data);
    return res;
  },
  //首页--选课(免费，付费，实训)
  async getIndex({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index", data);
    return res;
  },
  //首页更多课程接口
  async getMoreList({ commit }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Pub/MoreList", data);
    return res;
  },
  //首页分类上拉下拉
  async getProClass({ commit }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Pub/ProClass", data);
    return res;
  },
  //课程详情接口
  async getClassDet({ commit }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Pub/ClassDet", data);
    return res;
  },
  //详情---未购买课程（目录）
  async getCatalog({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/Catalog", data);
    return res;
  },
  //个人中心---取消收藏
  async getCancelCollection({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/CancelCollection", data);
    return res;
  },
  //收藏课程
  async getAddCollection({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/AddCollection", data);
    return res;
  },

  //个人中心---我的课程
  async getMyLesson({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/MyLesson", data);
    if (res.code == 200) {
      commit("SET_MYLESSONLIST", res.data);
    }
    return res;
  },
  //个人中心---学习报告接口
  async getPresentation({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/Presentation", data);
    return res;
  },
  //个人中心---学习进度详情
  async getMinePaceDetail({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/PaceDetail", data);
    return res;
  },
  //个人中心---学习进度
  async getMinePace({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/Pace", data);
    //过滤脏数据
    if (res.msg != "暂无数据!") {
      let str = [];
      str = res.data.filter((item, index) => {
        return item.id;
      });
      res.data = str;
    }

    return res;
  },
  async getsurvey({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/survey", data);
    return res;
  },
  async getsubAnswer({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/subAnswer", data);
    return res;
  },
  //获取验证码
  async getVerify({ commit }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/Login/Verify", data);
    return res;
  },
  //注册接口
  async getRegiser({ commit }, param) {
    const data = {
      ...param,
      password: md5(param.password),
      code: md5(udao),
    };
    const res = await api.post("/index/Login/Regiser", data);
    return res;
  },
  //个人中心---我的收藏
  async getCollection({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Mine/Collection", data);
    return res;
  },
  //修改密码--忘记密码
  async getChangePassword({ commit }, param) {
    const data = {
      ...param,
      password: md5(param.password),
      code: md5(udao),
    };
    const res = await api.post("/index/Login/ChangePassword", data);
    return res;
  },
  //班级详情接口
  async getclassDetail({ commit }, param) {
    const data = {
      ...param,
      code: md5(udao),
    };
    const res = await api.post("/index/index/classDetail", data);
    return res;
  },
  //学习--音频
  async getAudioList({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/AudioList", data);
    return res;
  },
  //签到接口
  async getSignIn({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/SignIn", data);
    return res;
  },
  //学习---目录
  async getClassCharter({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/ClassCharter", data);
    return res;
  },
  ///学习---已购买课程H5学习详情
  async getCharLer({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/CharLer", data);
    return res;
  },
  //实训考点接口
  async getKnowledgeList({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/KnowledgeList", data);
    return res;
  },
  //音频视频回调接口--用于记录学习
  async getVideoAudioCallback({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/VideoAudioCallback", data);
    return res;
  },
  //学习---视频
  async getVideoList({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/VideoList", data);
    return res;
  },
  //实训考点详情接口
  async getKnowledgeDetail({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/KnowledgeDetail", data);
    return res;
  },
  //修改实训考点状态
  async getSetKnowledgeStatus({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Study/SetKnowledgeStatus", data);
    return res;
  },
  //练答---章节练习列表
  async getChapterCatalog({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/ChapterCatalog", data);
    return res;
  },
  //考试封面
  async getMockCover({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/MockCover", data);
    return res;
  },
  //答题详情 1.模拟考试 5.章节练习 6.总题库
  async getMakeQuestion({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/MakeQuestion", data);
    return res;
  },
  //该接口用于用户章节 题库 模拟考试 记录学员的答案
  async getCalculateSectionTest({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/CalculateSectionTest", data);
    return res;
  },
  //错题
  async getErrorSubject({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/ErrorSubject", data);
    return res;
  },
  //错题回调
  async getErrorBankResults({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/ErrorBankResults", data);
    return res;
  },
  //记录成绩
  async getSectionTestScores({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Examination/SectionTestScores", data);
    return res;
  },
  //免费下单接口
  async getFreePurchase({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Order/FreePurchase", data);
    return res;
  },
  //pc端支付
  async getpcGenerateOrder({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao),
    };
    const res = await api.post("/index/Order/pc_GenerateOrder", data);
    return res;
  },
  // 旧接口 新闻列表
  async getNews1({ commit }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/News/newsList", data);
    return res;
  },
  // 旧接口 新闻详细
  async getnewsDetail1({ commit }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/News/newsDetail", data);
    return res;
  },
  // 新接口 新闻列表
  async getNews({ commit }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/News/index", data);
    return res;
  },
  // 新接口 新闻详细
  async getnewsDetail({ commit }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/News/newsDetail", data);
    return res;
  },
  // 新接口 加入我们
  async getinsJoinUs({ commit }, param) {
    const data = {
      ...param,
    };
    const res = await api.post("/index/Join_us/insJoinUs", data);
    return res;
  },
  //离线记录时长、
  async getscreenCuttingDuration({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao)
    };
    const res = await api.post("/index/Mine/screenCuttingDuration", data);
    return res;
  },
  //真实性核验
  async getsmsVerificationr({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao)
    };
    const res = await api.post("/index/Mine/smsVerification", data);
    return res;
  },
  //活跃度检测
  async getactivityDetection({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao)
    };
    const res = await api.post("/index/Mine/activityDetection", data);
    return res;
  },
  //人脸识别
  async getfaceRecognition({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao)
    };
    const res = await api.post("/index/face_recognition/faceRecognition", data);
    return res;
  },
  //H5拉取人脸图片接口
  async getFaceImage({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao)
    };
    const res = await api.post("/index/face_recognition/getFaceImage", data);
    return res;
  },
  //人脸识别认证
  async faceAuth({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao)
    };
    const res = await api.post("/index/face_recognition/faceAuth", data);
    return res;
  },
  //
  async fiveCount({ commit }, param) {
    const data = {
      ...param,
      code: md5(param.username + udao)
    };
    const res = await api.post("/index/Mine/fiveCount", data);
    return res;
  },
};
