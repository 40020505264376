<template>
  <div>
    <div class="header">
      <div class="wh1 flex ww">
        <img class="logo" @click="logo" src="../assets/logo@3x.png" alt="" />
        <div class="tablist flex">
          <div class="item">
            <el-select
              @change="tzwz"
              v-model="value"
              placeholder="请选择"
              style="width: 130px; height: 30px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item" @click="gohome">首页</div>
          <!-- <router-link class="item" to="/home">首页</router-link> -->
          <div class="item">
            <el-menu
              style="transform: translateY(8px)"
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              active-text-color="#137CFB"
              @select="handleSelect"
              text-color="#137cfb"
            >
              <!-- <el-menu-item index="1">培训课程</el-menu-item> -->
              <el-submenu index="1">
                <template slot="title">产品及解决方案</template>
                <el-menu-item index="企业培训">企业培训</el-menu-item>
                <el-menu-item index="机构云课堂">机构云课堂</el-menu-item>
                <el-menu-item index="开发及私有化">开发及私有化</el-menu-item>
                <!-- <el-menu-item index="企业培训">企业培训</el-menu-item> -->
              </el-submenu>
            </el-menu>
          </div>
          <router-link class="item" to="/training">培训课程</router-link>
          <router-link class="item" to="/information">培训资讯</router-link>
          <!-- <router-link class="item" to="/application">应用案例</router-link> -->
          <router-link class="item" to="/business">商务合作</router-link>
          <div class="item">
            <!-- 关于我们 -->
            <el-menu
              style="transform: translateY(8px)"
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              active-text-color="#137CFB"
              @select="handleSelect1"
              text-color="#137cfb"
            >
              <!-- <el-menu-item index="1">培训课程</el-menu-item> -->
              <el-submenu index="1">
                <template slot="title">关于我们</template>
                <el-menu-item index="关于我们">关于我们</el-menu-item>
                <el-menu-item index="联系我们">联系我们</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>
        <div class="tablists flex">
          <div class="items institution" style="position: relative">
            <!-- 学员中心 -->
            <div class="items" @click="gostudy2">学员中心</div>
            <!-- <div class="popup">
              <div class="popup_1" @click="gostudy">学员中心老版</div>
              <div class="popup_x"></div>
              <div class="popup_1" @click="gostudy1">学员中心新版</div>
            </div> -->
          </div>
          <div class="xian"></div>
          <div class="items institution" style="position: relative">
            机构中心
            <div class="popup">
              <div class="popup_1" @click="goorganization">机构中心老版</div>
              <div class="popup_x"></div>
              <div class="popup_1" @click="goorganization1">机构中心新版</div>
            </div>
          </div>
          <div class="xian"></div>

          <div class="items" @click="goenterprise">企业中心</div>
        </div>
      </div>
    </div>
    <div class="header1"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      activeIndex: "3",
      options: [
        {
          value: "https://www.zpton.com",
          label: "新版官网",
        },
        {
          value: "https://website.zpton.com",
          label: "老版官网",
        },
      ],
      value: "https://website.zpton.com",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    logo() {
      this.$router.push("/");
    },
    tzwz(item) {
      window.location.href = item;
    },

    gostudy1() {
      this.$router.push("/Layout/Learningcenter");
    },
    gostudy2() {
      this.$router.push("/Layout/Learningcenter");
    },
    goenterprise() {
      window.open("https://qysaas.zpton.com/");
    },
    goorganization() {
      // window.open("https://web.zpton.com/index.php/index/teacher_login");
      // 正式  //http://web2.zpton.com/index.php/index/teacher_login
      // 测试  // https://web.zpton.com/index.php/index/teacher_login
      window.open(
        process.env.NODE_ENV === "development"
          ? "https://web.zpton.com/index.php/index/teacher_login"
          : "https://web.zpton.com/index.php/index/teacher_login"
      );
    },
    goorganization1() {
      window.open("https://jgsaas.zpton.com/");
    },
    tz(val) {
      this.$router.push(val);
      if (this.userInfo == "") {
        this.$message({
          message: "暂无登录前往登录",
          type: "warning",
        });
        this.$router.push("/login");
      } else {
        this.$router.push("/Layout/Learningcenter");
      }
    },
    gohome() {
      this.$router.push({
        name: "home",
      });
    },
    handleSelect(key, keyPath) {
      if (key == "企业培训") {
        this.$router.push("/solution");
      }
      if (key == "机构云课堂") {
        this.$router.push("/Institutional");
      }
      if (key == "开发及私有化") {
        this.$router.push("/privatizationdevelopment");
      }
    },
    handleSelect1(key, keyPath) {
      if (key == "关于我们") {
        this.$router.push("/about");
      }
      if (key == "联系我们") {
        this.$router.push("/contact");
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.institution {
  &:hover .popup {
    display: block;
  }
  .popup {
    display: none;
    padding: 30px;
    position: absolute;
    left: -25px;
    top: 47px;
    width: 148px;
    height: 133px;
    background: url("../assets/hua.png");
    background-size: 100%;
    .popup_1 {
      line-height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9c9c9c;
      &:hover {
        color: #137cfb;
      }
    }
    .popup_x {
      height: 1px;
      border: 1px solid #f7f7f7;
    }
  }
}
.box {
  overflow: hidden;
}
.wh1 {
  width: 1300px;
  margin: 0 auto;
}
.header {
  position: fixed;
  z-index: 99999;
  top: 0px;
  width: 100%;
  height: 73px;
  background-color: #ffffff;
}
.header1 {
  height: 73px;
}

.ww {
  position: relative;
}
.logo {
  cursor: pointer;
  margin-top: 5px;
  width: 182px;
  height: 61px;
  vertical-align: middle;
}
.tablist {
  line-height: 73px;
  .item {
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #137cfb;
    margin: 0 18px;
  }
}
.tablists {
  position: absolute;
  right: 0;
  line-height: 73px;
  .xian {
    transform: translateY(30px);
    margin: 0 10px;
    width: 1px;
    height: 16px;
    background-color: #dedede;
  }
  .items {
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 520;
    color: #137cfb;
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6 !important;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #137cfb;
  font-size: 16px;
}
::v-deep.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 0px solid #409eff;
}
::v-deep .el-submenu__title i {
  color: #137cfb !important;
}
.header ::v-deep.el-submenu__title {
  padding: 0 0px !important;
}
</style>
