<template>
  <div class="footer">
    <div class="top">
      <div class="wh1">
        <div class="left">
          <div class="item">
            <div class="title">产品解决方案</div>
            <div class="items">
              <router-link to="/solution" class="items_1"
                >职培通·企业培训</router-link
              >
              <div class="items_2">助力企业人才培养</div>
            </div>
            <div class="items">
              <router-link to="/Institutional" class="items_1"
                >职培通·机构云课堂</router-link
              >
              <div class="items_2">助力机构线上培训</div>
            </div>
            <!-- <div class="items">
              <router-link to="/Institutional" class="items_1"
                >云课堂</router-link
              >
              <div class="items_2">助力个人能力自我提升</div>
            </div> -->
            <div class="items">
              <router-link to="/privatizationdevelopment" class="items_1"
                >开发/私有化</router-link
              >
              <div class="items_2">助力打造私有化平台</div>
            </div>
          </div>
          <router-link to="/training" class="item">
            <div class="title">培训课程</div>
            <div class="items">
              <router-link to="allcourses?type=3&is_type=1" class="items_1"
                >企业培训</router-link
              >
            </div>
            <div class="items">
              <router-link to="/allcourses?type=2&is_type=1" class="items_1"
                >技能补贴</router-link
              >
            </div>
            <div class="items">
              <router-link to="/allcourses?type=1&is_type=1" class="items_1"
                >个人兴趣</router-link
              >
            </div>
          </router-link>
          <div class="item">
            <div class="title">培训资讯</div>
            <div class="items">
              <router-link to="/information" class="items_1"
                >资讯一览</router-link
              >
              <div class="items_2">培训相关政策资讯</div>
            </div>
          </div>
          <!-- <div class="item">
            <div class="title">应用案例</div>
            <div class="items">
              <router-link to="/application" class="items_1"
                >案例一览</router-link
              >
              <div class="items_2">个人考证咨询</div>
            </div>
          </div> -->
          <div class="item">
            <div class="title">商务合作</div>
            <div class="items">
              <router-link to="/business" class="items_1">师资合作</router-link>
            </div>
            <div class="items">
              <router-link to="/business" class="items_1">课程合作</router-link>
            </div>
            <div class="items">
              <router-link to="/business" class="items_1">代理合作</router-link>
            </div>
            <div class="items">
              <router-link to="/business" class="items_1">品牌合作</router-link>
            </div>
            <div class="items">
              <router-link to="/business" class="items_1">行业合作</router-link>
            </div>
          </div>
          <div class="item cc">
            <div class="title">关于我们</div>
            <router-link to="/about" class="gy">关于职培通</router-link>
            <router-link to="/contact" class="gy">联系我们</router-link>
            <!-- <div class="gy">客户服务</div> -->
          </div>
        </div>
        <div class="right">
          <div class="ercode">
            <div class="box1">职培通咨询热线</div>
            <div class="box2">
              400-100-2925 <span>周一至周五 上午9:00到下午18:00</span>
            </div>
            <!-- <div class="box3">职培通技术服务热线</div>
            <div class="box4">
              400-100-2925 <span>服务时间：周一至周日</span>
            </div> -->
            <div class="imgcode">
              <div style="margin-right: 46px">
                <img src="../../src/assets/gongzhonghao.png" alt="" />
                <div
                  style="
                    text-align: center;
                    font-size: 14px;
                    margin-top: 13px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                  "
                >
                  关注职培通
                </div>
              </div>
              <div>
                <img src="../../src/assets/qrcode.jpg" alt="" />
                <div
                  style="
                    text-align: center;
                    font-size: 14px;
                    margin-top: 13px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                  "
                >
                  小程序二维码
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="but">
     <div class="wh">版权所有&#169上海职培通网络科技有限公司 Copyright&#169Shanghai zhipeitong Network Technology Co., Ltd Co.,Ltd. 增值电信业务经营许可证：<a href="https://beian.miit.gov.cn/#/Integrated/index">沪B2-20191085</a> 沪ICP备：<a href="https://beian.miit.gov.cn/#/Integrated/index">20010983号</a> 沪公网安备：<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502007330"> 44030502007330号"</a></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: "版权所有&#169上海职培通网络科技有限公司 Copyright&#169Shanghai zhipeitong Network Technology Co., Ltd Co.,Ltd. 增值电信业务经营许可证：沪B2-20191085 沪ICP备：20010983号 沪公网安备：44030502007330号",
    };
  },
};
</script>

<style lang="scss" scoped>
.cc {
  padding-right: 45px;
}
.wh1 {
  width: 1300px;
  margin: 0 auto;
}
.footer {
  height: 472px;
  .top {
    height: 421px;
    background-color: #333333;
    .left {
      border-right: 1px solid #5d5d5d;
      margin-top: 58px;
      float: left;
      display: flex;
      .item {
        margin-right: 63px;
        .title {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .items {
          margin-top: 25px;
          .items_1 {
            cursor: pointer;

            display: inline-block;
            font-size: 16px;
            margin-bottom: 10px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .items_2 {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #999999;
          }
        }
        .gy {
          display: block;
          margin-top: 25px;
          margin-bottom: 35px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
    .right {
      float: right;
      .ercode {
        margin-top: 58px;
        .box1 {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 5px;
        }
        .box2 {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 19px;
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .box3 {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 5px;
        }
        .box4 {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .imgcode {
          margin-top: 31px;
          display: flex;
          img {
            width: 119px;
            height: 119px;
          }
        }
      }
    }
  }
  .but {
    height: 51px;
    background-color: #202020;
    .wh {
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 51px;
      a {
        color: #ffffff;
      }
    }
  }
}
</style>
