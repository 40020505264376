export default {
  //登录信息
  SET_USERINFO(state, data) {
    state.userInfo = data;
    //state.userInfo.username = state.userInfo.phone;
  },
  //班级ID
  SET_CLASSID(state, data) {
    state.classid = data;
  },
  //修改登录信息--头像
  SET_USERINFOEDIT(state, data) {
    state.userInfo.headpic = data;
    let user = state.userInfo;
    state.userInfo = "";
    state.userInfo = user;
  },
  //微信手机号绑定
  SET_WX(state, data) {
    state.wx = data;
  },
  SET_SCHOOL(state, data) {
    state.school = data; //true 学校页
  },
  //支付类型
  SET_PAYTYPE(state, data) {
    state.payType = data;
  },
  //清空登录信息
  SET_RESETUSER(state, data) {
    state.userInfo = "";
  },
  SET_STUDYTYPE(state, data) {
    state.studyType = data;
  },
  //保存当前路由信息
  SET_ROUTER(state, data) {
    state.studyLayout.router = data;
  },
  //登录拦截之前的路由
  SET_ROUTERTO(state, data) {
    state.routerTo = data;
  },
  //个人中心--我的课程
  SET_MYLESSONLIST(state, data) {
    state.setting.MyLessonList = data;
  },
  //首页--选课
  SET_HOME(state, data) {
    state.home = data;
  },
  //详情
  SET_DETAILS(state, data) {
    state.details = data;
  },
  //修改练答index
  SET_TABINDEX(state, data) {
    state.tabsindex = data;
  },
  SET_STUDYDES(state, data) {
    state.studyDes = data;
  },
  //学习验证
  SET_LEARNING(state, data) {
    state.learning = data;
  },
  STE_HUMAN(state, data) {
    state.human = data;
  },
  SET_HUMAN_TYPE(state, data) {
    state.human_type = data;
  },
  SET_forcetoexit(state, data) {
    state.forcetoexit = data;
  },
};
